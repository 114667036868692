"use client";

import { NavigationitemComponent } from "movestic-blocks/components/__generated__/graphql";
import { getLink } from "movestic-blocks/utils/cms/storyblokHelpers";
import { ArrowRightIcon } from "movestic-core/icons/ArrowRightIcon";
import {
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuTrigger,
} from "movestic-core/tailwind/NavigationMenu";
import Link from "next/link";
import React from "react";
import { DetailedHTMLProps, LiHTMLAttributes } from "react";

//The onPointerXxx events are to force click on menu expand. Remove to activate on hover instead.
export default function NavigationMenuContainer({ item, forceMount }) {
    return (
        <NavigationMenuItem>
            <NavigationMenuTrigger
                onPointerMove={(event) => event.preventDefault()}
                onPointerLeave={(event) => event.preventDefault()}
            >
                {item.linkTitle}
            </NavigationMenuTrigger>
            <NavigationMenuContent
                onPointerMove={(event) => event.preventDefault()}
                onPointerLeave={(event) => event.preventDefault()}
                className={`grid grid-cols-4 gap-10 py-14 px-4 ${forceMount && "hidden"}`}
                {...(forceMount && { forceMount })}
            >
                {item.childItems?.map((childItem, index) => (
                    <MenuContentGroup item={childItem} key={`secondary_${index}`} />
                ))}
            </NavigationMenuContent>
        </NavigationMenuItem>
    );
}

function MenuContentGroup({ item }: { item: NavigationitemComponent }) {
    const url = getLink(item.navigationLink!);
    return (
        <div key={`secondary_${url}`}>
            <ul className="flex flex-col gap-6 " role="menu" aria-label="menu">
                <MenuHeading url={url} item={item} />
                {item.childItems?.map((subChildItem, index) => {
                    const subUrl = getLink(subChildItem.navigationLink);
                    return (
                        <ContentMenuLink
                            linkUrl={subUrl.linkUrl}
                            linkTitle={subChildItem.linkTitle}
                            key={`tert_${index}`}
                        />
                    );
                })}
            </ul>
        </div>
    );
}

function MenuHeading({ url, item }: { url: { linkUrl: any; linkTarget: any }; item: NavigationitemComponent }) {
    return (
        <li role="menuitem" data-radix-collection-item>
            {url.linkUrl ? (
                <Link
                    href={url.linkUrl}
                    legacyBehavior
                    passHref
                    className="flex gap-2 font-normal transition-all text-lightBlack hover:underline hover:gap-3 "
                >
                    <NavigationMenuLink className="flex gap-2 mb-2 no-underline transition-all text-h4 font-neutraface hover:underline hover:gap-3 hover:-mr-1">
                        {item.linkTitle}&nbsp;
                        <ArrowRightIcon />
                    </NavigationMenuLink>
                </Link>
            ) : (
                <div className="text-h4 font-neutraface text-lightBlack">{item.linkTitle}</div>
            )}
        </li>
    );
}

function ContentMenuLink({
    linkUrl,
    linkTitle,
    ...props
}: {
    linkUrl: string;
    linkTitle: string;
    props?: DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>;
}) {
    return (
        <li key={`tertrary_${linkUrl}`} {...props} role="menuitem">
            <Link href={linkUrl} legacyBehavior passHref>
                <NavigationMenuLink className="no-underline text-cherryDark hover:cherryRed hover:underline focus:underline">
                    {linkTitle}
                </NavigationMenuLink>
            </Link>
        </li>
    );
}
